import React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableNoMatchState from "../../../../common/TableNoMatchState";
import TableHeader from "./TableHeader/TableHeader";
import { columnDefinitions, contentDisplay } from "./config";
import { Alert } from "@amzn/awsui-components-react";
import { PropTypes } from "prop-types";

export const SCATableContainer = ({
  data,
  dataInput,
  budgets,
  budgetIdItem,
}) => {
  // Rounding
  const roundedBudgets = React.useMemo(() => {
    return budgets.map((budget) => {
      return Object.keys(budget).reduce((acc, key) => {
        if (typeof budget[key] === "number") {
          acc[key] = Math.floor(budget[key]); // Round down to nearest dollar
        } else {
          acc[key] = budget[key];
        }
        return acc;
      }, {});
    });
  }, [budgets]);

  // State variables to track current state of table
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectedBudget, setSelectedBudget] = React.useState({});
  const [preferences, setPreferences] = React.useState({
    pageSize: 10,
    contentDisplay: contentDisplay,
  });

  // Used to display currently selected budget in a banner above the table
  React.useEffect(() => {
    if (budgetIdItem in data && data[budgetIdItem]) {
      setSelectedBudget(
        roundedBudgets.find((item) => item.budgetId === data[budgetIdItem])
      );
    }
  }, [data]);

  // Filtering, sorting, pagination
  const {
    items,
    actions,
    collectionProps,
    filterProps,
    paginationProps,
    sortProps,
  } = useCollection(roundedBudgets, {
    filtering: {
      noMatch: (
        <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: { sortingField: "fundingContractName" },
        isDescending: false,
      },
    },
  });

  // Updates budget in data object to send to the backend
  const updateBudget = (budgetId) => {
    let currentValues = {};
    currentValues[budgetIdItem] = budgetId;
    data[budgetIdItem] = budgetId;
    dataInput(currentValues);
  };

  // The table component
  return (
    <div>
      {/* Enables or disables the banner if a budget is selected */}
      {/* Checking if selectedBudget and selectedBudget.fundingContractName exist is used in MDF case, where a budgetId exists, but no funding contract name because it is not SCA */}
      <div
        hidden={
          !data[budgetIdItem] ||
          !selectedBudget ||
          !selectedBudget.fundingContractName
        }
      >
        <Alert statusIconAriaLabel="" header="SCA selected">
          Agreement Name -{" "}
          {selectedBudget && selectedBudget.fundingContractName}
        </Alert>
        <br />
      </div>
      <div
        hidden={
          data[budgetIdItem] &&
          selectedBudget &&
          selectedBudget.fundingContractName
        }
      >
        <Alert
          statusIconAriaLabel=""
          header="Select a Strategic Collaboration Agreement"
        >
          Prioritize funding new requests from existing agreement allocations.
          Choose an agreement to use for your new funding request.
        </Alert>
        <br />
      </div>
      <React.Fragment>
        <Table
          {...collectionProps}
          selectedItems={selectedItems}
          onSelectionChange={({ detail }) => {
            setSelectedItems(detail.selectedItems);
            setSelectedBudget(detail.selectedItems[0]);
            updateBudget(detail.selectedItems[0].budgetId);
          }}
          columnDefinitions={columnDefinitions}
          columnDisplay={preferences.contentDisplay}
          enableKeyboardNavigation={true}
          items={[...items]}
          loadingText="Loading Strategic Collaboration Agreements"
          selectionType="single"
          trackBy="budgetId"
          filter={
            <TextFilter
              {...filterProps}
              filteringPlaceholder="Find SCA Budgets"
            />
          }
          pagination={<Pagination {...paginationProps} />}
          {...sortProps}
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              contentDisplayPreference={{
                options: columnDefinitions.map(({ id, header }) => ({
                  id,
                  label: header,
                })),
              }}
              pageSizePreference={{
                title: "Page size",
                options: [
                  { value: 5, label: "5 agreements" },
                  { value: 10, label: "10 agreements" },
                ],
              }}
            />
          }
          header={
            <TableHeader
              totalItemsCount={roundedBudgets.length}
              setSelectedItems={setSelectedItems}
              setSelectedBudget={setSelectedBudget}
              updateBudget={updateBudget}
            />
          }
        />
      </React.Fragment>
      <br />
    </div>
  );
};

SCATableContainer.propTypes = {
  data: PropTypes.object,
  dataInput: PropTypes.func,
  budgets: PropTypes.arrayOf(PropTypes.object),
  budgetIdItem: PropTypes.string,
};
